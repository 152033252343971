import { useState } from 'react';
import { isEmpty } from 'lodash';

import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { setFormFileAttachments } from './utils';
import { FileInputValue } from '@common/types/objects';
import { getFilesFromClipboard } from '@common/utils/get-files-from-clipboard';
import { ErrorType } from '../../message';

export const useChangeFileInput = (
  multiple: boolean | number = true,
) => {
  const [files, setFiles] = useState<FileInputValue[]>([]);

  const handleChange = (file?: FileInputValue, index: number = 0) => {
    setFiles((current) => {
      const newValue = [...current];
      if (file) newValue.splice(index, 1, file);
      else newValue.splice(index, 1);
      return newValue;
    });
  };

  return {
    files,
    setFiles,
    onChange: (file?: FileInputValue, index: number = 0) => {
      if (file && !multiple) {
        setFiles([file]);
        return;
      }
      if (file?.processing) {
        setFiles((current) => [...current, file]);
      } else if (file) {
        handleChange(file, index);
      }
    },
    onRemove: (index: number) => {
      handleChange(undefined, index);
    },
  };
};

export const useFileFromClipboard = (
  value: FileInputValue[] = [],
  multiple: boolean | number = true,
  onChange?: (file?: FileInputValue, index?: number) => void,
  setRejectedFiles?: React.Dispatch<React.SetStateAction<ErrorType[]>>,
) => {
  const organisationId = useAppSelector(getCurrentOrgId);
  return {
    onChange: (e: any) => {
      const files = getFilesFromClipboard(e);
      if (!isEmpty(files)) {
        e.preventDefault();
        setFormFileAttachments({
          files: multiple && multiple !== 1 ? files : [files[0]],
          value,
          organisationId,
          onChange,
          onError: setRejectedFiles,
          options: { processFile: true, multiple },
        });
      }
    }
  };
};
